import { useState, useEffect } from "react";
import { FormComponent, FormContainer } from "react-authorize-net";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { states } from "./states";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./App.css";
import CheckoutForm from "./CheckoutForm";

function App() {
  const API_BASE = "https://tshirt-server-4429b29f2efe.herokuapp.com/surf";
  // const API_BASE = "http://localhost:8080/surf";
  // const API_BASE = "https://99452a00d99b.ngrok.io";
  const linkId = window.location.pathname;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState("");
  const [totalDue, setTotalDue] = useState(0);
  const [intentId, setIntentId] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerId, setCustomerId] = useState("");

  const handleSuccess = (response) => {
    setLoading(true);
    axios
      .post(`${API_BASE}/save_payment/${linkId.split("/")[1]}`, {})
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        alert("Success! Payment info saved.");
      })
      .catch((err) => {
        alert("Error occurred. Check your card and billing.");
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_BASE}/checkSFID/${linkId.split("/")[1]}`)
      .then((response) => {
        if (response.data) {
          setLoading(false);
          setTotalDue(response.data.totalDue);
          setCustomerEmail(response.data.customer.email);
          setCustomerId(response.data.customer.id);
          setIntentId(response.data.stripe_intent_id);
        } else {
          // console.log("error!")
          // alert("Error occurred. Check your link or contact us");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        // alert("Error occurred. Check your link or contact us");
        setLoading(false);
      });
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: intentId,
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    const newEmail = email;
    axios
      .post(`${API_BASE}/setCustomerEmail/${customerId}`, { newEmail })
      .then((res) => {
        setLoading(false);
        setCustomerEmail(newEmail);
      })
      .catch((err) => {
        setLoading(false);
        console.log("ERROR", err);
        alert("Error!");
      });
  };
  const handleError = (response) => {
    console.log("ERROR", response);
  };

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(
    "pk_live_51KYBVxCqxjjh5mwrqg11N0iqomkW798zSqP7XauHX9K3Yhpm5A7gidOU4kVqa9ao34j2yW6x6ox1F1712LXwCTgT00jWYAHaBr"
  );

  if (linkId === "/thank_you") {
    return (
      <div>
        <h1>Thank you!</h1>
      </div>
    );
  }
  if (linkId === "/") {
    return (
      <div>
        <h1>Bad link</h1>
      </div>
    );
  }
  if (loading) {
    return (
      <div
        className=""
        style={{ backgroundColor: "#d3d3d3", alignItems: "center" }}
      >
        <h1 style={{ textAlign: "center" }}>
          <img
            style={{ height: 100 }}
            src="https://irp.cdn-website.com/eb3e7d66/dms3rep/multi/surfct-logo-black-text.svg"
          />
        </h1>
        <h1 style={{ textAlign: "center" }}>Loading</h1>
      </div>
    );
  }

  return (
    <div className="">
      <div
        className=""
        style={{ backgroundColor: "#d3d3d3", alignItems: "center" }}
      >
        <h1 style={{ textAlign: "center" }}>
          <img
            style={{ height: 100 }}
            src="https://irp.cdn-website.com/eb3e7d66/dms3rep/multi/surfct-logo-black-text.svg"
          />
        </h1>
      </div>
      {!customerEmail ? (
        <div>
          <Form onSubmit={handleEmail}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleEmail}>
              Next
            </Button>
          </Form>
        </div>
      ) : (
        <div className="">
          {intentId ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm amount={totalDue} handleSuccess={handleSuccess} />
            </Elements>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
